// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// function loadJSON(filePath) {
//   const json = loadTextFileAjaxSync(filePath, "application/json");

//   return JSON.parse(json);
// }

// function loadTextFileAjaxSync(filePath, mimeType) {
//   const xmlhttp = new XMLHttpRequest();

//   xmlhttp.open("GET", filePath, false);

//   if (mimeType != null) {
//     if (xmlhttp.overrideMimeType) {
//       xmlhttp.overrideMimeType(mimeType);
//     }
//   }

//   xmlhttp.send();
  
//   if (xmlhttp.status == 200) {
//     return xmlhttp.responseText;
//   }
//   else {
//     return null;
//   }
// }

// export const environment = loadJSON('/assets/config.json');

export const environment = {
  ProductTitle: "VyvExtranetAssuree 1.0",
  ProductVersion: "1.0.000",
  ProductType: "Test",
  production: true,
  EntrySystem: "33",
  BASE_URL: "https://vyv-extranet-api-p-001.azurewebsites.net/"
}